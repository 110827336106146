<template>
  <div class="renewal--option" v-if="activeTab === 'tab-renewal'">
    <div class="container">
      <div class="renewal--desc">{{ $t('product.renewal.ask') }}</div>
      <div class="renewal--select">
        <multiselect
          id="renewalType"
          v-model="renewalType"
          :options="renewalTypeList"
          :close-on-select="true"
          track-by="id"
          label="name"
          select-label=""
          selected-label=""
          deselect-label=""
          :placeholder="$t('product.renewal.placeholder')"
          style="max-width: 300px"
          :class="{ 'has-error': validation.hasError('renewalType') }"
        >
        </multiselect>
      </div>
      <div class="renewal--content" v-if="renewalType && renewalType.id === 1">
        {{ $t('product.renewal.instantRenewal') }}
        <div class="renewal--duration" v-if="duration">
          {{ $t('product.renewal.duration') }}: {{ duration.start }} - {{ duration.end }}
        </div>
      </div>
      <div class="renewal--content" v-else-if="renewalType && renewalType.id === 2">
        {{ $t('product.renewal.delayedRenewal') }}
        <div class="renewal--duration" v-if="duration">
          {{ $t('product.renewal.duration') }}: {{ duration.start }} - {{ duration.end }}
        </div>
      </div>
      <div
        class="d-flex justify-content-center"
        style="margin-top: -12px"
        v-if="validation.hasError('renewalType')"
      >
        <span class="val-error">{{ validation.firstError('renewalType') }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import { Validator } from 'simple-vue-validator';
import HelperMixin from '@/mixins/helpers';
import { mapState } from 'vuex';

require('vue-multiselect/dist/vue-multiselect.min.css');

export default {
  name: 'renewal-option',
  mixins: [HelperMixin],
  components: { Multiselect },
  props: ['activeTab'],
  data: () => ({
    renewalTypeList: [
      {
        id: 1,
        name: 'Aktifkan Sekarang',
      },
      {
        id: 2,
        name: 'Aktifkan Nanti',
      },
    ],
  }),
  computed: {
    ...mapState({
      user: (state) => state.global.user,
      duration: (state) => state.product.duration,
    }),
    renewalType: {
      get() {
        return this.$store.state.product.renewalType;
      },
      set(value) {
        this.$store.commit('product/SET_RENEWAL_TYPE', value);
        if (value) {
          this.$store.dispatch('product/getDuration', value.id === 1);
        }
      },
    },
  },
  validators: {
    renewalType(value) {
      if (this.activeTab === 'tab-renewal') {
        return Validator.value(value).required(this.$i18n.t('errors.renewalType.required'));
      } else {
        return Validator.value(value);
      }
    },
  },
  mounted() {
    this.renewalType = null;
  },
  methods: {
    async validate() {
      return this.$validate();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/utils/variables';

.renewal--option {
  .container {
    max-width: 800px;
    margin: 36px auto 0 auto;
    @media #{$phones} {
      margin-top: 18px;
    }
  }

  .renewal--desc {
    text-align: center;
    margin-bottom: 24px;
  }

  .renewal--select {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  .renewal--content {
    text-align: center;
    margin-bottom: 12px;

    .renewal--duration {
      margin-top: 4px;
      font-family: Poppins-SemiBold;
    }
  }
}
</style>
