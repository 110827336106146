<template>
  <div>
    <listing-availability-option
      :activeTab="activeTab"
      ref="listingAvailabilityOption"
    ></listing-availability-option>
    <renewal-option :activeTab="activeTab" ref="renewalOption"></renewal-option>
    <div class="d-flex justify-content-center">
      <button
        class="btn btn-primary"
        @click="openCheckout"
        :disabled="selectedProduct === null || loading"
      >
        <v-icon>mdi-cart</v-icon>
        <span>{{ $t('product.btn') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import HelperMixin from '@/mixins/helpers';
import ListingAvailabilityOption from '@/components/product/listing-availability-option.vue';
import RenewalOption from '@/components/product/renewal-option.vue';

export default {
  name: 'product-button',
  components: { RenewalOption, ListingAvailabilityOption },
  mixins: [HelperMixin],
  props: ['activeTab'],
  computed: {
    ...mapState({
      selectedProduct: (state) => state.product.selectedProduct,
      renewalType: (state) => state.product.renewalType,
      loading: (state) => state.checkout.loading,
      selectedQty: (state) => state.product.selectedQty,
      selectedDuration: (state) => state.product.selectedDuration,
    }),
  },
  methods: {
    validate() {
      let validationArray = [];
      validationArray.push(this.$refs.listingAvailabilityOption.validate());
      validationArray.push(this.$refs.renewalOption.validate());

      return Promise.all(validationArray).then(function (results) {
        return (
          results.filter(function (result) {
            console.log('qqqqqq', result);
            return !result;
          }).length === 0
        );
      });
    },
    async openCheckout() {
      try {
        const isValid = await this.validate();
        if (isValid) {
          let data = await this.$store.dispatch('checkout/getCheckoutInfo', {
            product: this.selectedProduct,
            renewalType: this.renewalType,
            qty: this.selectedQty,
            duration: this.selectedDuration ? this.selectedDuration.value : null,
          });
          if (data !== null) {
            let query = null;
            if (this.renewalType != null) {
              query = {
                direct_activation: this.renewalType.id === 1,
              };
            }
            await this.$router.push({
              path: `/checkout`,
              query: query,
            });
          }
        } else {
          this.scrollToErrorSection(this.$el);
        }
      } catch (e) {
        console.log('ERROR CHECKOUT PRODUCT: ', e);
      }
    },
  },
};
</script>

<style scoped></style>
